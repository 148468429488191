export const submitContactUsFormSubmissionEmail = ({ email, name, message, contactUsLambdaUrl, contactUsLambdaApiKey }) => {
  return fetch(contactUsLambdaUrl, {
    method: 'post',
    headers: {
      'content-type': 'application/json',
      'x-api-key': contactUsLambdaApiKey
    },
    body: JSON.stringify({
      email,
      name,
      message
    })
  })
    .then((res) => res.json())
}
