import * as constants from './constants'
import sdk from 'services/vigilanceHubService'

class ConfigActions {
  fetchTheme = ({ themeId }) => ({
    type: constants.FETCH_THEME,
    themeId,
    promise: (dispatch, getState) => {
      const { config } = getState()
      return sdk.themes.fetchTheme({ id: themeId })
    }
  })

  resetStore = () => ({
    type: constants.RESET_STORE
  })
}

export default new ConfigActions()
