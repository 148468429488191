import * as colors from '../config/theme/colors'
import { bodyFont } from '../config/theme/fonts'

import useTranslate from 'hooks/useTranslate'
import { useWebsiteConfig } from 'hooks/configHooks'

import { CookiePolicy as RACookiePolicy } from '@redant/cookie-policy'

const CookiePolicy = () => {
  const translate = useTranslate()

  const theme = {
    fontFamily: bodyFont,
    colors: {
      focusColor: colors.buttonBgColorPrimary
    },
    toggle: {
      backgroundColor: colors.cookiePolicyIconBgColor,
      iconColor: colors.cookiePolicyIconColor
    },
    slider: {
      backgroundColor: colors.cookiePolicySliderBgColour
    },
    button: {
      backgroundColor: colors.buttonBgColorPrimary,
      color: colors.buttonTextColorPrimary
    },
    panel: {
      maxWidth: 375,
      color: colors.bodyFontColor,
      backgroundColor: colors.cookiePolicyBgColor
    }
  }

  const websiteConfig = useWebsiteConfig()
  const { gaTrackingId, cookiePrefix = 'RA' } = websiteConfig
  return (
    <RACookiePolicy
      gaId={gaTrackingId}
      initialOptOut
      locale={'en'}
      pageLink={'/cookies'}
      hideAnalyticsSection={false}
      translations={translate}
      theme={theme}
      cookiePrefix={cookiePrefix}
    />
  )
}

export default CookiePolicy
