const initialState = {
  app: {},
  resources: {},
  newReport: {},
  auth: {},
  organisationDetails: {},
  config: {},
  formViews: []
}

export default initialState
