import { actions as newReportActions } from '../newReport'
import { constants as authConstants } from '../auth'
import { PENDING, SUCCESS, FAIL } from '../../reduxPromise'

class NewReportMiddleware {
  resetInitialValuesOnLogin = ({ dispatch, getState }) => next => (action) => {
    next(action)
    const { isCustomerMode, isUsingRecentUser = false } = action

    if (action.status === SUCCESS && action.type === authConstants.LOGIN) {
      dispatch(newReportActions.resetInitialValues())
    }
  }
  resetInitialValuesOnLogout = ({ dispatch, getState }) => next => (action) => {
    next(action)

    if (action.status === SUCCESS && action.type === authConstants.LOGOUT) {
      dispatch(newReportActions.resetInitialValues())
    }
  }
}

export default new NewReportMiddleware()
