import _ from 'lodash'

class AppReducers {
  setAppReady = (state, action) => {
    return {
      ...state,
      appReady: _.get(action, 'appReady')
    }
  }
}

export default new AppReducers()
