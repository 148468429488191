import _ from 'lodash'
import { PENDING } from '../../reduxPromise'
import { createSelector } from 'reselect'
import { getWebsiteConfiguration } from '../config/selectors'

export const getIsLoading = state => state.organisationDetails.status === PENDING
export const getCurrentOrganisation = state => _.get(state, 'organisationDetails.organisation', {})
export const getCurrentOrganisationId = state => _.get(state, 'organisationDetails.organisation.id')
export const getCurrentOrganisationDetails = state => _.get(state, 'organisationDetails.organisation.details')
export const getCurrentOrganisationName = state => _.get(state, 'organisationDetails.organisation.name')
export const getCurrentOrganisationDrugsLists = state => _.get(state, 'organisationDetails.organisation.drugsLists', [])
export const getCurrentOrganisationDrugMetaImports = state => _.get(state, 'organisationDetails.organisation.drugMetaImports', [])
export const getCurrentOrganisationPlatform = state => _.get(state, 'organisationDetails.organisation.platform')
export const getCurrentOrganisationProfessions = state => _.get(state, 'organisationDetails.organisation.professions', [])
export const getCurrentOrganisationReceivers = state => _.get(state, 'organisationDetails.organisation.receivers', [])
export const getCurrentOrganisationCountry = state => _.get(state, 'organisationDetails.organisation.country')

export const getCurrentOrganisationSelector = createSelector(
  [getCurrentOrganisation],
  (organisation) => organisation
)

export const getHCPProfessionsOptions = createSelector(
  [getCurrentOrganisationProfessions],
  (professions) => {
    const hcpProfessions = _.filter(professions, prof => prof.type === 'Health Care Professional' || prof.type === 'Company')
    return _.map(hcpProfessions, profession => {
      const { name, id, professionGroupId, professionGroup } = profession
      return { label: name, value: id, professionGroupId, professionGroup }
    })
  }
)

export const getPublicProfessionsOptions = createSelector(
  [getCurrentOrganisationProfessions],
  (professions) => {
    return _.chain(professions)
      .filter(prof => prof.type === 'Public' || prof.type === 'Parent' || prof.type === 'Company')
      .map(prof => ({
        value: prof.id,
        label: prof.name
      }))
      .value()
  }
)
export const shouldPrefillOccupationValue = createSelector(
  [
    getHCPProfessionsOptions,
    getPublicProfessionsOptions,
    getWebsiteConfiguration
  ], (hcp, mop, webConfig) => {
    const showOnlyPublicProfessions = _.get(webConfig, 'showOnlyPublicProfessions')
    if (showOnlyPublicProfessions) return 'public'
    if (_.isEmpty(hcp) && !_.isEmpty(mop)) return 'public'
    if (_.isEmpty(mop) && !_.isEmpty(hcp)) return 'hcp'
    return false
  }
)
