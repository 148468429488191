import _ from 'lodash'
import moment from "moment"

export const getState = state => _.get(state, 'tempUserDetails')
export const getIsValidSession = ({ reportId }) => state => !!_.get(getState(state), reportId)
export const getUserDetails = ({ reportId }) => state => _.get(getState(state), `[${reportId}]userDetails`, {})
export const getLatestUserDetails = () => state => {
  const tempUserDetails = getState(state)

  if (!_.isEmpty(tempUserDetails)) {
    const sortedUserDetails = _.sortBy(tempUserDetails, (value) => {
      return new Date(_.get(value, 'userDetails.createdAt'));
    });

    const latestUserDetails = _.last(sortedUserDetails);
    
    const lastObject = _.get(latestUserDetails, 'userDetails')
    return lastObject
  } else {
    return null
  }
}
  