import * as constants from './constants'
import reducers from './reducers'
import initialState from '../../initialState'

const defaultState = initialState.newReport

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.RESET:
      return reducers.reset(state, action)
    case constants.UPDATE_USER_DETAILS:
      return reducers.updateUserDetails(state, action)
    default:
      return state
  }
}
