import * as constants from './constants'

class TempUserDetailsActions {
  reset = () => ({
    type: constants.RESET
  })
  updateUserDetails = ({ reportId, values }) => ({
    type: constants.UPDATE_USER_DETAILS,
    values,
    reportId
  })
}

export default new TempUserDetailsActions()
