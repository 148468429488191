import sdk from 'services/vigilanceHubService'
import _ from 'lodash'

import * as constants from './constants'

class OrganisationDetailsActions {
  fetchOrganisationDetails = () => ({
    type: constants.FETCH_ORGANISATION_DETAILS,
    promise: (dispatch, getState) => {
      const { config } = getState()
      const organisationId = _.get(config, 'websiteConfig.vigilanceHubOrganisationId')
      return sdk.organisations.fetchOrganisation({ id: organisationId })
    }
  })
}

export default new OrganisationDetailsActions()
