import _ from 'lodash'
import initialState from '../../initialState'
import moment from "moment"

class TempUserDetailsReducers {
  reset = (state) => {
    return {
      ...initialState.tempUserDetails
    }
  }
  updateUserDetails = (state, action) => {
    const reportId = action.reportId
    const currentForm = state[reportId] || {}
    return {
      ...state,
      [reportId]: {
        ...currentForm,
        userDetails: {
          ...currentForm.userDetails,
          ...action.values,
          createdAt: moment()
        }
      }
    }
  }
}

export default new TempUserDetailsReducers()
