import * as constants from './constants'

class ConfigActions {
  setWebsiteConfig = ({ websiteConfig }) => ({
    type: constants.SET_WEBSITE_CONFIG,
    payload: websiteConfig
  })

  agreeTerms = () => ({
    type: constants.SET_AGREE_TERMS
  })

  clearAgreeTerms = () => ({
    type: constants.CLEAR_AGREE_TERMS
  })

  resetStore = () => ({
    type: constants.RESET_STORE
  })
}

export default new ConfigActions()
