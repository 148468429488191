import _ from 'lodash'
import { PENDING, SUCCESS, FAIL } from '../../reduxPromise'

class ThemeReducers {
  fetchTheme = (state, action) => {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          action: action.type
        }
      case SUCCESS:
        return {
          ...state,
          result: {
            ...action.result.details
          },
          status: SUCCESS
        }
      case FAIL:
        return {
          ...state,
          status: FAIL,
          error: action.error
        }
      default:
        return state
    }
  }

}

export default new ThemeReducers()
