import * as constants from './constants'
import reducers from './reducers'

export const defaultState = {
  organisation: {}
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_ORGANISATION_DETAILS:
      return reducers.fetchOrganisationDetails(state, action)
    default:
      return state
  }
}
