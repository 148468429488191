import * as constants from './constants'
import reducers from './reducers'
import initialState from '../../initialState'

const defaultState = initialState.app

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.SET_APP_READY:
      return reducers.setAppReady(state, action)
    default:
      return state
  }
}
