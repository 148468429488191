import _ from 'lodash'
import { PENDING, SUCCESS, FAIL } from '../../reduxPromise'
class ConfigReducers {

  setWebsiteConfig = (state, action) => {
    return {
      ...state,
      websiteConfig: action.payload
    }
  }

  setAgreeTerms = (state, action) => {
    return {
      ...state,
      agreeTerms: true
    }
  }

  clearAgreeTerms = (state, action) => {
    return {
      ...state,
      agreeTerms: false
    }
  }
}

export default new ConfigReducers()
