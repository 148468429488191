import { connect } from 'react-redux'

// Example from https://github.com/zeit/next.js/blob/canary/examples/with-google-analytics/lib/gtag.js

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  const gaTrackingId = process.env.gaTrackingId
  if (process.browser && window[`ga-disable-${gaTrackingId}`] === false) {
    window.gtag('config', gaTrackingId, {
      page_path: url
    })
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  const gaTrackingId = process.env.gaTrackingId
  if (process.browser && window[`ga-disable-${gaTrackingId}`] === false) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value
    })
  }
}
