import * as constants from './constants'
import { submitContactUsFormSubmissionEmail } from '../../../services/lambdaService'

class AppActions {
  setAppReady = ({ appReady }) => ({
    type: constants.SET_APP_READY,
    appReady
  })
  
  submitContactUsForm = ({ name, email, message }) => ({
    type: constants.SUBMIT_CONTACT_US_FORM,
    promise: async (dispatch, getState) => {
      const { config } = getState()
      const contactUsLambdaUrl = _.get(config, 'websiteConfig.contactUsLambdaUrl')
      const contactUsLambdaApiKey = _.get(config, 'websiteConfig.contactUsLambdaApiKey')
      return submitContactUsFormSubmissionEmail({ 
        name, 
        email,
        message,
        contactUsLambdaUrl,
        contactUsLambdaApiKey
      })
    }
  })
}

export default new AppActions()
