import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  error: undefined,
  status: undefined,
  websiteConfig: undefined,
  agreeTerms: false
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.RESET_STORE:
      return defaultState
    case constants.SET_WEBSITE_CONFIG:
      return reducers.setWebsiteConfig(state, action)
    case constants.SET_AGREE_TERMS:
      return reducers.setAgreeTerms(state, action)
    case constants.CLEAR_AGREE_TERMS:
      return reducers.clearAgreeTerms(state, action)
    default:
      return state
  }
}
