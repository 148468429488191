import { combineReducers } from 'redux'
import { resourceReducer } from 'redux-resource'

import initialState from '../../initialState'
import resourceTypes from './resourceTypes'
import * as constants from './constants'

const defaultState = initialState.resources

const resourceReducers = {}
for (const resource of resourceTypes) {
  resourceReducers[resource] = resourceReducer(resource)
}

const combinedReducers = combineReducers(resourceReducers)

const resourcesReducer = (state, action) => {
  if (action.type === constants.RESET) {
    return defaultState
  }
  return combinedReducers(state, action)
}

export default resourcesReducer
