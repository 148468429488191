import _ from 'lodash'
import initialState from '../../initialState'

class NewReportReducers {
  reset = (state) => {
    return {
      ...initialState.newReport
    }
  }
  updateInitialValues = (state, action) => {
    const reportId = action.reportId
    const currentForm = state[reportId] || {}
    return {
      ...state,
      [reportId]: {
        ...currentForm,
        initialValues: {
          ...currentForm.initialValues,
          ...action.values
        }
      }
    }
  }
  updateFormConfig = (state, action) => {
    const reportId = action.reportId
    const currentForm = state[reportId] || {}
    return {
      ...state,
      [reportId]: {
        ...currentForm,
        formConfig: {
          ...currentForm.formConfig,
          ...action.values
        }
      }
    }
  }
  updateUserDetails = (state, action) => {
    const reportId = action.reportId
    const currentForm = state[reportId] || {}
    return {
      ...state,
      [reportId]: {
        ...currentForm,
        userDetails: {
          ...currentForm.userDetails,
          ...action.values
        }
      }
    }
  }
  resetInitialValues = (state) => {
    return {
      ...state,
      initialValues: {}
    }
  }
}

export default new NewReportReducers()
