import * as constants from './constants'
import reducers from './reducers'
import initialState from '../../initialState'

const defaultState = initialState.newReport

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.UPDATE_INITIAL_VALUES:
      return reducers.updateInitialValues(state, action)
    case constants.UPDATE_FORM_CONFIG:
      return reducers.updateFormConfig(state, action)
    case constants.RESET:
      return reducers.reset(state, action)
    case constants.UPDATE_USER_DETAILS:
      return reducers.updateUserDetails(state, action)
    case constants.RESET_INITIAL_VALUES:
      return reducers.resetInitialValues(state, action)
    default:
      return state
  }
}
