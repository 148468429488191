// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN || 'https://448af6379f0a4dcb8c6abe94438c9858@o309471.ingest.sentry.io/6454274',

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process?.env?.nodeEnv === 'development' ? 1.0 : 0.2,
  environment: process?.env?.REACT_APP_NODE_ENV || 'development'
})
